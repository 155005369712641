import { Injectable } from '@angular/core' ;
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonFunctions{
   
  public deviceInfo = {};

  constructor( private deviceService: DeviceDetectorService, private activatedRoute: Router ){
    this.detectDevice();
    this.isMobile();
  }

  detectDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }
  
  isMobile(){
    if(this.deviceService.isMobile() || this.deviceService.isTablet()){
      return true;
    }
    return false;
  }

  divideNode(Node: any, Option: string){
    let nodes, config: any = {
      'museums': {web: 3, mobile: 1},
      'obra_de_arte': {web: 3, mobile: 3},
      'instrumento': {web: 3, mobile: 3},
      'estampilla': {web: 3, mobile: 3},
      'pieza_coleccion_monedas_billetes': {web: 3, mobile: 3},
      'pieza_arqueologica': {web: 3, mobile: 3},
      'home_activities': {web: 4, mobile: 4},
      'home_kids': {web: 4, mobile: 4},
      'home_layout_activities': {web: 4, mobile: 4},
      'home_actividad_musical_activities': {web: 4, mobile: 4},
      'detalle_activities': {web: 4, mobile: 4},
      'slider_collections_others': {web: 4, mobile: 1},
      'services': {web: 12, mobile: 12},
      'search': {web: 12, mobile: 12},
      'lightbox': {web: 6, mobile: 3},
      'thumbnail': {web: 6, mobile: 3},
      'tags': {web: 4, mobile: 1}
    };
    let _divOption = (this.isMobile()) ? config[Option].mobile : config[Option].web;

    if(typeof Node.nodes !== 'undefined') {
      nodes = Node.nodes;
    }else {
      nodes = Node;
    }

    let f_inc = _divOption, f_ini = 0, f_fin = f_inc, _v = 0, _mod = 0,_div = nodes.length, items = [], sub_items = [];
    if(nodes) {
      _mod = _div % _divOption;
      if (_mod === 0) {
        _div = _div / _divOption;
      }else {
        _div = (_div + 1) / _divOption;
      }
      for (let i = 0; i < _div; i++) {
        for (let f = f_ini; f < f_fin; f++) {
          if (typeof nodes[f] !== 'undefined') {
            sub_items[_v] = nodes[f];
            _v++;
          }
        }
         _v = 0;
         f_ini = f_fin;
         f_fin = f_fin + f_inc;
         items[i] = sub_items;
         sub_items = [];
      }
    }
    return items;
  }

  divideByColumns(nodes: any, number: number){
    var va: any = [], key = 0;
    switch (number) {
      case 2:
        va = [[], []];
      break;
      case 3:
        va = [[], [], []];
      break;
      case 4:
        va = [[], [], [], []];
      break;
      default:
        va = [[]];
    }
    nodes.forEach(function(data: any) {
      va[key].push(data);
      key++;
      if(key == number){
        key = 0;
      }
    },this);
   return va;
  }
  
  paramsEndPoint(obj: any){      
    let str = [];
    for (let p in obj) {
      if (obj.hasOwnProperty(p) && obj[p] != null && obj[p] != 0) {
        str.push(p + '=' + obj[p]);
        //str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&')
  }

  getQuerySearch(base: string, parameters: any) {
    let query = base + '?', keys = Object.keys(parameters);
    keys.forEach((v) => {
      if(parameters[v]!=='' && parameters[v]!==null && parameters[v]!==0 && parameters[v]!=='Todos' && parameters[v]!=='Todas') {
        query += '&' + v + '=' + parameters[v];
      }
    });
    return query.replace(/&/, '');
  }

  orderSelectForm(select: any, name = 'Seleccione', value = 0, selected = true){
    let select2 = [];
    select2.push({name: name, value: value, selected: selected});
    if(typeof select.nodes !== 'undefined'){
      select.nodes.forEach(function(value: any, index: number) {
         select2.push({name: value.name ? value.name : value.titulo, value: value.tid ? value.tid : value.nid ? value.nid : value.id, selected: false});
      }, this);
    }
    return select2;
  }
  
  pagination(n: any, cp: any, p: any, pv: any, nx: any, o: any, s = false) {
    let pagination = [], c = Math.floor(Math.ceil(n/cp));
    if(s){
      pagination.push({name: p, active: true});
    }else {
      if(o) {
        o.forEach(function(value: any, index: number) {
           pagination.push({name: value.name, active: value.name == p ? true : false});
        }, this);
      }else {
        for (let i = 1; i <= c; i++) {
          if(p == 1) {
              if(i <= 6 || i >= c-2){
                pagination.push({name: i, active: i == p ? true : false});
              }
          }
          if(pv){
            if(i <= p && i >= p-4 || i >= c-2) {
              //if(i <= p && p >= 1){
                pagination.push({name: i, active: i == p ? true : false});
              //}
            }
          }
          if(nx){
            if(i >= p && i <= p+5 || i >= c-2){
              pagination.push({name: i, active: i == p ? true : false});
            }
          }
        }
      }
    }

    return pagination;
  }

  depureBody(body: string) {
    return body.replace(new RegExp('="/sites/default/files/',"gi"), '="'+ environment.assets +'/sites/default/files/').replace(new RegExp('="//assets.',"gi"), 'https://'+environment.assetssubdomain+'.').replace(new RegExp('="//admin.',"gi"), 'https://'+environment.assetssubdomain+'.').replace(new RegExp('http://assets.banrepcultural.org/',"gi"), environment.assets).replace(new RegExp('https://assets.banrepcultural.org/',"gi"), environment.assets).replace(new RegExp('http://admin.banrepcultural.org/',"gi"), environment.assets).replace(new RegExp('https://admin.banrepcultural.org/',"gi"), environment.assets);
  }

  trackByNid(n: any){
    let tb: any = [];
    n.forEach(function(v: any, k: number) {
      if(k == 0) tb.push(v);
      if(k > 0) {
        let tbf = tb.filter((c: any) => c.nid === v.nid);
        if(tbf.length <= 0) tb.push(v);
      }
    },this);
    return tb;
  }
}
