import { Directive, HostBinding, HostListener, Input, OnChanges, OnDestroy, isDevMode } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, UrlTree } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { Subscription } from 'rxjs';
type QueryParamsHandling = 'merge' | 'preserve' | '';

@Directive({
  selector: 'a[brcLink]'
})
export class BrcLinkDirective implements OnChanges, OnDestroy {
  
  @HostBinding() href: string;
  @HostBinding('attr.target')
  @Input() target: string;
  @Input() queryParams: {[k: string]: any};
  @Input() fragment: string;
  @Input() queryParamsHandling: QueryParamsHandling;
  @Input() preserveFragment: boolean;
  @Input() skipLocationChange: boolean;
  @Input() replaceUrl: boolean;
  private commands: any[] = [];
  private subscription: Subscription;
  private preserve: boolean;

  constructor(private router: Router, private route: ActivatedRoute,
    private locationStrategy: LocationStrategy) {
    this.subscription = router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        this.updateTargetUrlAndHref();
      }
    });
  }

  @Input()
  set brcLink(commands: any[]|string) {
    if (commands != null) {
      this.commands = Array.isArray(commands) ? commands : [commands];
    } else {
      this.commands = [];
    }
    this.updateTargetUrlAndHref();
  }

  @Input()
  set preserveQueryParams(value: boolean) {
    if (isDevMode() && <any>console && <any>console.warn) {
      console.warn('preserveQueryParams is deprecated, use queryParamsHandling instead.');
    }
    this.preserve = value;
  }

  ngOnChanges(changes: {}): any { this.updateTargetUrlAndHref(); }
  
  ngOnDestroy(): any { this.subscription.unsubscribe(); }

  @HostListener('click', ['$event.button', '$event.ctrlKey', '$event.metaKey', '$event.shiftKey'])
  
  onClick(button: number, ctrlKey: boolean, metaKey: boolean, shiftKey: boolean): boolean {
    if (button !== 0 || ctrlKey || metaKey || shiftKey) {
      return true;
    }
    if (typeof this.target === 'string' && this.target !== '_self') {
      return true;
    }
    const extras = {
      skipLocationChange: attrBoolValue(this.skipLocationChange),
      replaceUrl: attrBoolValue(this.replaceUrl),
    };
    this.router.navigateByUrl(this.urlTree, extras);

    return false;
  }

  private updateTargetUrlAndHref(): void {
    let path = this.commands[0] || '';
    if (path.indexOf('http') >= 0) {
      this.href = path;
    } else {
      this.href = this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(this.urlTree));
    }
  }

  get urlTree(): UrlTree {
    return this.router.createUrlTree(this.commands, {
      relativeTo: this.route,
      queryParams: this.queryParams,
      fragment: this.fragment,
      queryParamsHandling: this.queryParamsHandling,
      preserveFragment: attrBoolValue(this.preserveFragment)
    });
  }
}

function attrBoolValue(s: any): boolean {
  return s === '' || !!s;
}
